import * as React from "react"
import { Link } from "gatsby"
import "../../css/_section-audit.scss"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { RecCard } from "../../components/resources"

const breadData = [
  {
    id: 1,
    ink: "/",
    label: "HOME",
  },
  {
    id: 3,
    ink: "/resources/",
    label: "Resources",
  },
]

const blogs = [
  {
    hrf: "https://sp1.sdcdn.app/pdf/MDR-Solution-Brief.pdf",
    rec: "../images/resource/solutionbrief_cardimage.jpg",
    subHeader: "Solution Brief",
    title: "VigilantMDR",
    content:
      "Battle-tested human analysis with advanced technologies that enable companies to become resistant to the impact of attacks.",
  },

  {
    hrf: "https://sp1.sdcdn.app/pdf/MEDR-Solution-Brief.pdf",
    rec: "../images/resource/solutionbrief_cardimage.jpg",
    subHeader: "Solution Brief",
    title: "VigilantMEDR",
    content:
      "Protecting assets in the Cloud, at home, on prem and everywhere in between.",
  },

  {
    hrf: "https://sp1.sdcdn.app/pdf/MNDR-Solution-Brief.pdf",
    rec: "../images/resource/solutionbrief_cardimage.jpg",
    subHeader: "Solution Brief",
    title: "VigilantMNDR",
    content:
      "Patented fully managed service that includes the tech, the experts and the process to keep networks safe.",
  },

  {
    hrf: "https://sp1.sdcdn.app/pdf/V365-Solution-Brief.pdf",
    rec: "../images/resource/solutionbrief_cardimage.jpg",
    subHeader: "Solution Brief",
    title: "Vigilant365™",
    content: "Enhanced Microsoft 365 detection – from a multitude of vectors.",
  },

  {
    hrf: "https://sp1.sdcdn.app/pdf/Pen-Test-Solution-Brief.pdf",
    rec: "../images/resource/Vigilant365.jpg",
    subHeader: "Solution Brief",
    title: "Penetration Testing",
    content: "Preventative defense through cyber-attack simulation.",
  },
  {
    hrf: "https://sp1.sdcdn.app/pdf/MVDR-Solution-Brief.pdf",
    rec: "../images/resource/solutionbrief_cardimage.jpg",
    subHeader: "Solution Brief",
    title: "VigilantMVDR",
    content:
      "A proactive and strategic approach to vulnerability management & risk reduction.",
  },
]
const SolutionBriefs = () => {
  return (
    <Layout>
      <Seo title="Security Threat Audit" />
      <div className="breadcrumbs">
        <nav className="container-fluid p-sides-med">
          <ul>
            {breadData.map(breadcrumb => (
              <li key={breadcrumb.id}>
                <Link to={breadcrumb.ink}>{breadcrumb.label}</Link>
              </li>
            ))}
          </ul>
        </nav>
      </div>
      <div className="grid--bg--for-it-ops-3a">
        <section className="section-title-top ">
          <div className="container">
            <div className="row">
              <div className="section-col-1">
                <h1>RESOURCES</h1>
              </div>
              <div className="section-col-2"></div>
            </div>
          </div>
        </section>
        <section className="section-client-cards">
          <div className="container">
            <div className="row pb-5 blog--nav">
              <div className="col-sm-2 ">
                <Link to="/resources/" activeClassName="blog--nav-active">
                  ALL RESOURCES
                </Link>
              </div>
              <div className="col-sm-2">
                <Link
                  to="/resources/solution-briefs"
                  activeClassName="blog--nav-active"
                >
                  SOLUTION BRIEFS
                </Link>
              </div>
              <div className="col-sm-2">
                <Link
                  to="/resources/success-stories"
                  activeClassName="blog--nav-active"
                >
                  SUCCESS STORIES
                </Link>
              </div>
              <div className="col-sm-2">
                <Link
                  to="/resources/battlecards"
                  activeClassName="blog--nav-active"
                >
                  BATTLECARDS
                </Link>
              </div>
            </div>
            <div className="row">
              <ul className="client-cards-lists">
                {blogs.map(blog => {
                  return (
                    <RecCard
                      imgSrc={blog.rec}
                      subHeader={blog.subHeader}
                      title={blog.title}
                      content={blog.content}
                      hrf={blog.hrf}
                    />
                  )
                })}
              </ul>
            </div>
          </div>
        </section>
      </div>
      <div className="breadcrumbs">
        <nav className="container-fluid p-sides-med">
          <ul>
            {breadData.map(breadcrumb => (
              <li key={breadcrumb.id}>
                <Link to={breadcrumb.ink}>{breadcrumb.label}</Link>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </Layout>
  )
}
export default SolutionBriefs
